import React, { useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import { Radio, Select, Switch, notification, Icon, DatePicker ,Button, Row, Col,Input} from "antd";
import axios from "axios";

const AddTFLRange = (props) => {
    const [points, setPoints] = useState("");
    const [nok, setNok] = useState("");
    const [monthlyPer, setMonthlyPer] = useState("");
    const [validity, setValidity] = useState("");
    const token = localStorage.getItem("token");
  
    const handleSubmit = (event) => {
      event.preventDefault();
    };

  
    const submitAddvertisement = async (e) => {
  let formData = {};
  formData.giftCardAmt = points;
  formData.finalAmount = nok;
  formData.monthlyPer = monthlyPer;
  formData.validity = validity;
  
      const res = await axios.post(
        `${endpoint}/api/v1/admin/branch/addTFLRange`,
        formData,
        {
          headers: {
            // "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
      if(res.status == 200){
        notification.open({
              message: "Your Range saved successfully!",
              icon: <Icon type="check-circle" style={{ color: "green" }} />,
              duration: 3,
            });
            props.indexPage();
      }
    };
  return (
    <div style={{ margin: "0 30%" }}>
      <form onSubmit={handleSubmit} className="form-horizontal box">
      <Row gutter={24} className="justify-content-center m-3">
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="flex flex-column mb-2"
        >
          <p className="mb-0">Amount Paid (Kr.) : </p>{" "}
          <Input
            onChange={(e) => setPoints(e.target.value)}
            value={points}
            // placeholder="Add Coins"
            type="Number"
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="flex flex-column"
        >
          <p className="mb-0">Gift Card Amount (Kr.) :</p>{" "}
          <Input
            onChange={(e) => setNok(e.target.value)}
            value={nok}
            // placeholder="Add NOK"
            type="Number"
          />
        </Col>
      </Row>
      <Row gutter={24} className="justify-content-center m-3 mt-4" style={{paddingTop:"20px"}}>
      <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="flex flex-column"
        >
          <p className="mb-0">Monthly usage ( % )</p>{" "}
          <Input
            onChange={(e) => setMonthlyPer(e.target.value)}
            value={monthlyPer}
            // placeholder="Add NOK"
            type="Number"
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="flex flex-column"
        >
          <p className="mb-0">Validity ( Months )</p>{" "}
          <Input
            onChange={(e) => setValidity(e.target.value)}
            value={validity}
            // placeholder="Add NOK"
            type="Number"
          />
        </Col>
      </Row>
      <Row gutter={24} className="justify-content-center m-3 mt-4" style={{paddingTop:"15px"}}>
      <button
          type="submit"
          style={{ marginTop: "15px", width: "100%" }}
          className="button is-primary"
          onClick={submitAddvertisement}
        >
          Save
        </button>
      </Row>
      </form>
    </div>
  )
}

export default AddTFLRange
