import React, { Component, Fragment } from "react";
import RestaurantForm from "../restaurantForm/RestaurantForm";
import Notification from "react-bulma-notification";
import endpoint from "../../helpers/Endpoint";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { Tooltip, Layout, Row, Col, Switch } from "antd";
import s3url from "../../helpers/s3";
const { Content } = Layout;
class Restaurant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      restaurant: [],
      superCats: [],
      inputVisible: false,
      devicesData: [],
      finalDevices: []
    };
  }

  componentDidMount = () => {
    var dt = moment().startOf("month");
    // alert(dt);
    this.getdevices();
    this.setState({
      restaurant: this.props.restaurant
      // superCats: this.props.superCats
    });
    // console.log("STAUTE supercats", this.props.superCats);
  };

  handleRestaurantEdit = () => {
    this.setState((prevState) => ({
      editing: !prevState.editing
    }));
  };

  saveRestaurant = (
    e,
    editing,
    name,
    email,
    phoneNo,
    taxNo,
    line1,
    line2,
    city,
    pin,
    state,
    country,
    tflamount,
    description,
    accountNumber,
    tdEnhPercentage,
    id,
    website,
    driveoutCharge,
    taccountantEmail,
    isRestaurantAvailable,
    restaurantEmail,
    kitchenpermission,
    barpermission,
    tippermission,
    deliverypermission,
    pickuppermission,
    selectedDevices,
    longitude,
    latitude,
    couponExpiresIn,
    couponPermissionChange,
    vippsPermissionChange,
    merchantSerialNumber,
    merchantSerialNumberPos,
    isTreeDriveAllowed,
    tdDiscountPermission,
    isNewReports,
    isWebOpperKonto,
    isDriveMyself,
    isTdvipps,
    isFindDriver,
    isTreeDriveDriveOutAllowed,
    isTreeDrivePickUpAllowed,
    tdDistance,
    tdServiceCharge,
    tdExtraPriceIncrease,
    tdFlatRateDriveOut,
    tdExtraRateDriveOut,
    tdStaticDriveOut,
    tdFlatDriveOutDistance,
    isWeekly,
    isVendorAllowed,
    isIngredientAllowed,
    tdDiscount,
    tdDiscountPickUp,
    reserveTablePermission,
    minimumOrder,
    machAccess,
    branchAddress,
    searchCity,
    branchType,
    range1Max,
    range1Min,
    discount1,
    range2Max,
    range2Min,
    discount2,
    range3Max,
    range3Min,
    discount3,
    range1MaxTA,
    range1MinTA,
    discount1TA,
    range2MaxTA,
    range2MinTA,
    discount2TA,
    range3MaxTA,
    range3MinTA,
    discount3TA,
    range4MaxTA,
    range4MinTA,
    discount4TA,
    range1MaxIDriveMyself,
    range1MinIDriveMyself,
    perKmFee1IDriveMyself,
    range2MaxIDriveMyself,
    range2MinIDriveMyself,
    perKmFee2IDriveMyself,
    range3MaxIDriveMyself,
    range3MinIDriveMyself,
    perKmFee3IDriveMyself,
    perDelFeeIDriveMyself,
    categoryBackColor,
    categoryTitleColor,
    productColor,
    priceColor,
    ingredentTextColor,
    orderNowBtnBackground,
    orderNowTextColor,
    viewCartTextColor,
    viewProductColor,
    qrcodeTablepermission,

    accountId,
    tidypayusername,
    tidypaypassword,
    tidypaypasswordweb,
    tidypaypassword6inch,

    minDepositAllowed,
    advanceDeposit,
    minDiffAllowed,
    webpayment,
    machinepayment,

    tdTakeAwayDelay,
    tdDriveOutDelay
  ) => {
    console.log("inside edit save restaurant edit---->", longitude);
    console.log("inside edit save restaurant edit---->", latitude);
    console.log("inside edit save restaurant edit---->", couponExpiresIn);
    console.log(
      "inside edit save restaurant couponPermissionChange---->",
      couponPermissionChange
    );
    console.log(
      "inside edit save restaurant vippsPermissionChange---->",
      vippsPermissionChange
    );
    console.log("inside edit save restaurant------>", merchantSerialNumber);
    console.log("weeklyReport------>", isWeekly);
    console.log("isVendorAllowed------>", isVendorAllowed);
    console.log("qr table permisiiom------>", qrcodeTablepermission);
    e.preventDefault();
    const regex = /^https{0,1}:\/\//i;
    if (regex.test(website)) {
      website = website.toLowerCase();
    } else {
      website = `http://${website}`;
    }

    const data = JSON.stringify({
      name,
      email,
      phoneNo,
      taxNo,
      line1,
      line2,
      city,
      pin,
      state,
      country,
      tflamount,
      description,
      accountNumber,
      tdEnhPercentage,
      website,
      driveoutCharge,
      taccountantEmail,
      isRestaurantAvailable,
      restaurantEmail,
      kitchenpermission,
      barpermission,
      tippermission,
      deliverypermission,
      pickuppermission,
      selectedDevices,
      categoryBackColor,
      categoryTitleColor,
      productColor,
      longitude,
      latitude,
      couponExpiresIn,
      couponPermissionChange,
      vippsPermissionChange,
      merchantSerialNumber,
      merchantSerialNumberPos,
      isTreeDriveAllowed,
      tdDiscountPermission,
      isNewReports,
      isWebOpperKonto,
      isDriveMyself,
      isTdvipps,
      isFindDriver,
      isTreeDriveDriveOutAllowed,
      isTreeDrivePickUpAllowed,
      tdDistance,
      tdServiceCharge,
      tdExtraPriceIncrease,
      tdFlatRateDriveOut,
      tdExtraRateDriveOut,
      tdStaticDriveOut,
      tdFlatDriveOutDistance,
      isWeekly,
      isVendorAllowed,
      isIngredientAllowed,
      tdDiscount,
      tdDiscountPickUp,
      reserveTablePermission,
      minimumOrder,
      machAccess,
      branchAddress,
      searchCity,
      branchType,
      range1Max,
      range1Min,
      discount1,
      range2Max,
      range2Min,
      discount2,
      range3Max,
      range3Min,
      discount3,
      range1MaxTA,
      range1MinTA,
      discount1TA,
      range2MaxTA,
      range2MinTA,
      discount2TA,
      range3MaxTA,
      range3MinTA,
      discount3TA,
      range4MaxTA,
      range4MinTA,
      discount4TA,
      range1MaxIDriveMyself,
    range1MinIDriveMyself,
    perKmFee1IDriveMyself,
    range2MaxIDriveMyself,
    range2MinIDriveMyself,
    perKmFee2IDriveMyself,
    range3MaxIDriveMyself,
    range3MinIDriveMyself,
    perKmFee3IDriveMyself,
    perDelFeeIDriveMyself,
    priceColor,
    ingredentTextColor,
    orderNowBtnBackground,
    orderNowTextColor,
    viewCartTextColor,
    viewProductColor,
    qrcodeTablepermission,

    accountId,
    tidypayusername,
    tidypaypassword,
    tidypaypasswordweb,
    tidypaypassword6inch,
    // tidypaypermission,
    // dinteropermission,
    // verifonepermission,
    minDepositAllowed,
    advanceDeposit,
    minDiffAllowed,
    webpayment,
    machinepayment,
    tdTakeAwayDelay,
    tdDriveOutDelay,
    });
    console.log("admin/rest", data);
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/restaurant/${id}`, {
      method: "PUT",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("errors", result);
          this.openNotification("error", result.data);
        } else {
          console.log(result);
          this.openNotification("success", result.message);
          this.props.history.push("/");
        }
      })
      .catch((error) => console.log(error));
  };

  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };
  // saveSuperCategory(sCategory) {
  //   console.log("YOU HAVE REACHED SUCCESSFULLY###", sCategory);
  // }
  saveSuperCategory = (id, scat, type) => {
    // e.preventDefault();
    // const { superCat } = this.state;
    const supercatData = {
      branchId: id,
      name: scat,
      sCatType: type
    };
    console.log("SUPER CAT DATA POST", supercatData);
    const token = localStorage.getItem("token");

    fetch(`${endpoint}/prcat/supercat`, {
      method: "POST",
      body: JSON.stringify(supercatData),
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            console.log(result);
          } else {
            console.log("SUPER CATEGORY ADDED", result);
            this.props.getsuperCats(id);
            this.setState({ inputVisible: false });
            this.openNotification("success", result.message);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
    console.log("YOU ARE GETTIN SUPER CAT", supercatData);
  };

  getdevices = () => {
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    const token = localStorage.getItem("token");
    fetch(`${endpoint}/admin/getinactivedevices`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result.data);
        } else {
          console.log("got the devices+++", result);
          // const data = result.data.filter(item => !item.isDeleted);
          this.setState({ devicesData: result.data, isLoaded: true });
        }
      })
      .catch((error) => console.log(error));
  };

  onChangeQr = (e) => {
    this.props.onAllowQrChange(e);
  };

  deleteSuperCategory = (id) => {
    const token = localStorage.getItem("token");
    fetch(`${endpoint}/prcat/deletesupercat/${id}`, {
      method: "GET",
      headers: {
        "x-auth-token": token
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            console.log(result);
          } else {
            console.log("SUPER CATEGORY ADDED", result);
            this.openNotification("success", result.message);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };
  copy = () => {
    const textarea = document.getElementById("menu-button-code");
    textarea.select();
    document.execCommand("copy");
  };
  logr = (e) => {
    alert(e);
  };

  removeDevice = (restId, deviceId) => {
    var postData = {
      branchId: restId,
      deviceId: deviceId
    };
    var postData = new Object();
    postData.branchId = restId;
    postData.deviceId = deviceId;
    console.log("POSTDATA DATA", postData);
    var hdr = localStorage.token;
    fetch(`${endpoint}/admin/removedevices`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            // this.props.currentPage = "restaurantList";
            // localStorage.setItem("logo", result.data);
            this.openNotification("success", "Device Un-Binded");
            // this.getResProfile();
            this.setState({ editing: false });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  getRestReports = (id) => {
    this.props.history.push(`/restreports/${id}`);
    // this.props.navigation.navigte("/restreport/:")
    // const token = localStorage.getItem("token");
    // fetch(`${endpoint}/admin/singlerestreport/${id}`, {
    //   method: "GET",
    //   headers: {
    //     "x-auth-token": token
    //   }
    // })
    //   .then(res => res.json())
    //   .then(
    //     result => {
    //       if (result.status === "failure") {
    //         console.log(result);
    //       } else {
    //         console.log("getting reset Reports", result);
    //         this.openNotification("success", result.message);
    //       }
    //     },
    //     error => {
    //       this.setState({
    //         isLoaded: true,
    //         error
    //       });
    //     }
    // );
  };

  render() {
    const { restaurant, superCats } = this.props;
    console.log("SUPER CATS PROPS", superCats);
    const { editing } = this.state;
    if (!editing) {
      return (
        <Fragment>
          <div className="columns is-centered">
            <div className="column" style={{ padding: "20px 0" }}>
              <div className="card">
                <header className="card-header">
                  <h4
                    className="card-header-title"
                    style={{ fontSize: "22px", paddingLeft: "25px" }}
                  >
                    {restaurant.name}
                  </h4>
                  <Link
                    to={`/taxes/${restaurant.slug}`}
                    className="card-header-icon"
                    aria-label="more options"
                    style={{
                      color: "#000",
                      padding: 24,
                      fontWeight: "bold",
                      fontSize: 15,
                      borderRightWidth: 2,
                      borderColor: "black"
                    }}
                  >
                    Taxes
                  </Link>

                  <Link
                    to={`/machineconfig/${restaurant.slug}`}
                    className="card-header-icon"
                    aria-label="more options"
                    style={{
                      color: "#000",
                      padding: 24,
                      fontWeight: "bold",
                      fontSize: 15,
                      borderRightWidth: 2,
                      borderColor: "black"
                    }}
                  >
                    Verifone
                  </Link>

                  <Link
                    to={`/super-categories/${restaurant.slug}`}
                    className="card-header-icon"
                    aria-label="more options"
                    style={{
                      color: "#000",
                      padding: 24,
                      fontWeight: "bold",
                      fontSize: 15,
                      borderRightWidth: 2,
                      borderColor: "black"
                    }}
                  >
                    Super Categories
                  </Link>
                  <a
                    onClick={() => this.getRestReports(restaurant._id)}
                    className="card-header-icon"
                    aria-label="more options"
                    style={{
                      color: "#000",
                      padding: 24,
                      fontWeight: "bold",
                      fontSize: 15,
                      borderRightWidth: 2,
                      borderColor: "black"
                    }}
                  >
                    Reports
                  </a>
                  <a
                    onClick={this.handleRestaurantEdit}
                    className="card-header-icon"
                    aria-label="more options"
                    style={{
                      color: "#000",
                      padding: 24,
                      fontWeight: "bold",
                      fontSize: 15,
                      borderWidth: 2
                    }}
                  >
                    Edit
                  </a>
                </header>
                <div className="card-content" id="dataDisplay">
                  <div className="content">
                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column">
                        <img
                          src={`${s3url}/${restaurant.logo}`}
                          style={{ maxWidth: 100, maxHeight: 100 }}
                        />
                      </div>
                      <div className="column">
                        <p>{restaurant.description}</p>
                        <a
                          href={restaurant.website}
                          style={{ fontWeight: "bold" }}
                        >
                          {restaurant.website}
                        </a>
                      </div>
                    </div>
                    {restaurant.devices.map((element, index) => {
                      return (
                        <div>
                          <div
                            className="columns"
                            style={{ background: "#363636" }}
                          >
                            <div
                              className="column has-text-weight-semibold"
                              style={{ color: "white" }}
                            >
                              <i
                                class="fab fa-android"
                                style={{ color: "gold", fontSize: 15 }}
                              ></i>{" "}
                              Device POS-
                              {element.deviceId &&
                                element.deviceId.deviceSerialNumber}
                            </div>
                          </div>

                          <div style={{ display: "flex" }}>
                            <div className="column has-text-weight-semibold">
                              Devices Model Nr.
                            </div>
                            <div className="column">
                              {element.deviceId && element.deviceId.modelNumber}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* <Layout style={{ padding: 30 }}>
                      <h3>ONLINE ORDERING PANEL THEME COLORS</h3>
                      <Row>
                        <Col span={2}>
                          <Tooltip
                            // placement="bottom"
                            title="Category Button background color"
                          >
                            <div
                              style={{
                                width: 30,
                                height: 30,
                                borderWidth: 1,
                                borderColor: "black",
                                borderRadius: 30,
                                background: `${restaurant.colors.categoryBackColor}`
                              }}
                            ></div>
                            {restaurant.colors.categoryBackColor}
                          </Tooltip>
                        </Col>

                        <Col span={2}>
                          <Tooltip
                            // placement="bottom"
                            title="Category Button Title color"
                          >
                            <div
                              style={{
                                width: 30,
                                borderWidth: 1,
                                borderColor: "black",
                                height: 30,
                                borderRadius: 30,
                                background: `${restaurant.colors.categoryTitleColor}`
                              }}
                            ></div>
                            {restaurant.colors.categoryTitleColor}
                          </Tooltip>
                        </Col>

                        <Col span={2}>
                          <Tooltip
                            // placement="bottom"
                            title="Category Button background color"
                          >
                            <div
                              style={{
                                width: 30,
                                height: 30,
                                borderWidth: 1,
                                borderColor: "black",
                                borderRadius: 30,
                                background: `${restaurant.colors.productColor}`
                              }}
                            ></div>
                            {restaurant.colors.productColor}
                          </Tooltip>
                        </Col>
                        <Col span={2}>
                          <Tooltip title="Price Color">
                            <div
                              style={{
                                width: 30,
                                height: 30,
                                borderWidth: 1,
                                borderColor: "black",
                                borderRadius: 30,
                                background: `${restaurant.colors.priceColor}`
                              }}
                            ></div>
                            {restaurant.colors.priceColor}
                          </Tooltip>
                        </Col>

                        <Col span={2}>
                          <Tooltip title="Ingredents text color">
                            <div
                              style={{
                                width: 30,
                                height: 30,
                                borderWidth: 1,
                                borderColor: "black",
                                borderRadius: 30,
                                background: `${restaurant.colors.ingredentTextColor}`
                              }}
                            ></div>
                            {restaurant.colors.ingredentTextColor}
                          </Tooltip>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={2}>
                          <Tooltip
                            placement="bottom"
                            title="Order now button background color"
                          >
                            <div
                              style={{
                                width: 30,
                                height: 30,
                                borderWidth: 1,
                                borderColor: "black",
                                borderRadius: 30,
                                background: `${restaurant.colors.orderNowBtnBackground}`
                              }}
                            ></div>
                            {restaurant.colors.orderNowBtnBackground}
                          </Tooltip>
                        </Col>

                        <Col span={2}>
                          <Tooltip
                            placement="bottom"
                            title="Order now button text color"
                          >
                            <div
                              style={{
                                width: 30,
                                borderWidth: 1,
                                borderColor: "black",
                                height: 30,
                                borderRadius: 30,
                                background: `${restaurant.colors.orderNowTextColor}`
                              }}
                            ></div>
                            {restaurant.colors.orderNowTextColor}
                          </Tooltip>
                        </Col>

                        <Col span={2}>
                          <Tooltip
                            placement="bottom"
                            title="View Cart Text Color (view cart background color is same as order Now background color)"
                          >
                            <div
                              style={{
                                width: 30,
                                height: 30,
                                borderWidth: 1,
                                borderColor: "black",
                                borderRadius: 30,
                                background: `${restaurant.colors.viewCartTextColor}`
                              }}
                            ></div>
                            {restaurant.colors.viewCartTextColor}
                          </Tooltip>
                        </Col>
                        <Col span={2}>
                          <Tooltip
                            placement="bottom"
                            title="View Product Color (below every product)"
                          >
                            <div
                              style={{
                                width: 30,
                                height: 30,
                                borderWidth: 1,
                                borderColor: "black",
                                borderRadius: 30,
                                background: `${restaurant.colors.viewProductColor}`
                              }}
                            ></div>
                            {restaurant.colors.viewProductColor}
                          </Tooltip>
                        </Col>
                      </Row>
                    </Layout> */}

                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Restaurant Email
                      </div>
                      <div className="column">{restaurant.restaurantEmail}</div>
                    </div>
                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Phone No.
                      </div>
                      <div className="column">{restaurant.phoneNo}</div>
                    </div>
                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Tax No.
                      </div>
                      <div className="column">{restaurant.taxNo}</div>
                    </div>
                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Account No.
                      </div>
                      <div className="column">{restaurant.accountNumber}</div>
                    </div>
                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Address
                      </div>
                      <div className="column">
                        <p>
                          {restaurant.address.line1}, {restaurant.address.line2}
                          ,{restaurant.address.city}, {restaurant.address.pin}
                        </p>
                        <p>
                          {restaurant.address.state},{" "}
                          {restaurant.address.country}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Manager Name
                      </div>
                      <div className="column">
                        {restaurant.employeeId.firstName}{" "}
                        {restaurant.employeeId.lastName}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Manager Email
                      </div>
                      <div className="column">
                        {restaurant.email}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Restaurant Super Categories
                      </div>
                      <div className="column">
                        {superCats &&
                          superCats.map((spcat, index) => {
                            return <p key={spcat._id}>{spcat.name},</p>;
                          })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Driveout Charges
                      </div>
                      <div className="column">{restaurant.driveoutCharge}</div>
                    </div>

                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Button Preview
                      </div>
                      <div className="column">
                        <a
                          style={{
                            background: "#CCA249",
                            padding: "5px 20px",
                            color: "#fff",
                            border: "1px solid #CCA249",
                            textDecoration: "none",
                            borderRadius: "4px"
                          }}
                          href={`https://web.a-board.tech/restaurant/${restaurant._id}`}
                          target="_blank"
                        >
                          Order Now
                        </a>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      <div className="column has-text-weight-semibold">
                        Button Code
                        {/* <button
                          style={{ color: "#1890ff", marginLeft: 20 }}
                          onClick={this.copy}
                        >
                          Click to Copy
                        </button> */}
                      </div>
                      <div className="column">
                        <span>
                          <textarea
                            id="menu-button-code"
                            className="small-textarea"
                            rows="4"
                            cols="60"
                            value={`<a style="padding: 5px 20px;color: #0274be; border: 1px solid #0274be;text-decoration: none; border-radius: 4px;" href="https://web.a-board.world/restaurant/${restaurant._id}">Order Now</a>`}
                          />
                        </span>
                      </div>
                    </div>

                    {restaurant.isQrAllow && (
                      <div
                        style={{
                          display: "flex"
                        }}
                      >
                        <div className="column has-text-weight-semibold">
                          Table QR-Code
                        </div>
                        <div className="column">
                          <img src={restaurant.tableQrCode}></img>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else if (editing) {
      return (
        <RestaurantForm
          editing={true}
          removeDevice={(restId, deviceId) =>
            this.removeDevice(restId, deviceId)
          }
          logr={() => this.logr()}
          devicesData={this.state.devicesData}
          restaurant={restaurant}
          inputVisible={this.state.inputVisible}
          superCats={superCats}
          deleteSuperCategory={(id) => this.deleteSuperCategory(id)}
          saveSuperCategory={(id, sCategory, type) =>
            this.saveSuperCategory(id, sCategory, type)
          }
          saveRestaurant={this.saveRestaurant}
        />
      );
    }
  }
}

export default withRouter(Restaurant);
