import React, { Component } from "react";
import Notification from "react-bulma-notification";
import "../../../node_modules/react-bulma-notification/build/css/index.css";
import endpoint from "../../helpers/Endpoint";
import s3url from "../../helpers/s3";

class VendorIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      icons: []
    };
  }

  componentDidMount = () => {
    this.showIcons();
  };

  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  showIcons = () => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/vendorIcon`, {
      method: "GET",
      headers: {
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
        } else {
          this.setState({ icons: result.data, isLoaded: true });
        }
      })
      .catch((error) => console.log(error));
  };

  handleChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("icon", file);
    formData.append("type", "vendor");
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/icon`, {
      method: "POST",
      body: formData,
      headers: {
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
          this.openNotification("error", result.data);
        } else {
          this.showIcons();
          this.openNotification("success", result.message);
        }
      })
      .catch((error) => console.log(error));
  };

  deleteIcon = (id) => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/icon/${id}`, {
      method: "DELETE",
      headers: {
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
          this.openNotification("success", result.data);
        } else {
          this.showIcons();
          this.openNotification("success", result.message);
        }
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { icons, isLoaded } = this.state;
    return (
      <div
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          borderRadius:10
        }}
      >
        <div className="tile is-parent">
          <article className="tile is-child notification ">
          <h1 style={{color:"black", fontWeight:"800" , fontSize:"18px", paddingBottom:"30px"}}>Vendor Icons - </h1>
            <div className="content">
              <div className="file">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="icon"
                    onChange={this.handleChange}
                  />
                  {/* <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload" />
                    </span>
                    <span className="file-label">Choose a file…</span>
                  </span> */}
                </label>
              </div>
            </div>
          </article>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent:"space-between",
          }}
        >
          {isLoaded
            ? icons.map((icon, index) => {
                return (
                  <div className="image-icon-box" key={icon._id}>
                    <img
                      src={`${s3url}/${icon.path}`}
                      className="image-icon"
                      alt="icon"
                    />
                    <a
                      className="delete is-medium"
                      onClick={() => this.deleteIcon(icon._id)}
                    />
                  </div>
                );
              })
            : "Loading..."}
        </div>
      </div>
    );
  }
}

export default VendorIcons;
