import { Button, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import CustomPopupConfirm from "../popups/popupconfirm";
import Notification from "react-bulma-notification";
import { Link } from "react-router-dom";

const CouponList = (props) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isPopup, setisPopup] = useState(false);
  const [couponId, setcouponId] = useState("");
  const [branchName, setBranchName] = useState("");

  const getRestaurant = async (branchId) => {
      const res = await axios.get(`${endpoint}/api/v1/admin/branch/getbranch?branchId=${branchId}`)
      .then(res => setBranchName(res?.data?.data?.name))
      .catch(err => console.log(err));
  }

  const openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 3 });
  };
  useEffect(() => {
    setPage(1);
    setPageSize(10);
    return () => {};
  }, []);

  useEffect(() => {
    setPage(1);
    setPageSize(10);

    return () => {};
  }, [props.branchId, props.couponType]);

  const showConfirmationDialog = (id) => {
    setisPopup(true);
    setcouponId(id);
  };

  const cancelConformationDialog = () => {
    setisPopup(false);
  };

  const changeStatus = async (id, value) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.post(
        `${endpoint}/api/v1/admin/coupons/changeCouponStatus`,
        { couponId: id, value },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
          }
        }
      );
      let date = new Date();
      if (props.couponType == "All Coupons") {
        props.updateData(props.date, props.branchId);
      } else {
        props.updateDataCouponType(props.couponType, props.branchId);
      }

      openNotification("success", res.data.message);
    } catch (error) {
      openNotification("error", error.message);
    }
  };

  const resendCoupon = async (couponId) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${endpoint}/api/v1/admin/coupons/resendcoupon`,
        { couponId: couponId },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
          }
        }
      );
      openNotification("success", res.data.message);
    } catch (error) {
      openNotification("error", error.message);
    }
  };

  const deleteCoupon = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${endpoint}/api/v1/admin/coupons/deletecoupan`,
        { id: couponId },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
          }
        }
      );
      let date = new Date();
      props.updateData(date, props.branchId);
      setisPopup(false);
      openNotification("success", res.data.message);
    } catch (error) {
      openNotification("error", error.message);
    }
  };

  const columns = [
    {
      title: "Sr No",
      key: "sr.no",
      render: (text, record, index) => {
        return index + 1 + (page - 1) * pageSize;
      }
    },
    {
      title: "Coupon Code",
      dataIndex: "coupanCode",
      key: "coupanCode",
      render: (text, record) => {
        return <Link to={`/coupon-detail?coupon_id=${record._id}`}>{text}</Link>;
      }
    },
    {
      title: "Coupon Value",
      dataIndex: "coupanValue",
      key: "coupanValue",
      render: (text, record, index) => {
        return `Kr. ${text}`;
      }
    },
    {
      title: " Remaining Value",
      dataIndex: "reamainigValue",
      key: "reamainigValue",
      render: (text, record, index) => {
        return `Kr. ${text ? text.toFixed(2) : "0"}`;
      }
    },
    {
      title: "Coupon Value for this month ",
      dataIndex: "reamainigValue",
      key: "reamainigValue",
      render: (text, record, index) => {
        return `Kr. ${record.cardAmtForThisMonth ? record.cardAmtForThisMonth.toFixed(2) : record.reamainigValue.toFixed(2)}`;
      }
    },
    {
      title: " Remaining Value for this month ",
      dataIndex: "reamainigValue",
      key: "reamainigValue",
      render: (text, record, index) => {
        return `Kr. ${record.remAmtForThisMonth ? record.remAmtForThisMonth.toFixed(2) : record.reamainigValue.toFixed(2)}`;
      }
    },
    {
      title: "Generated On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => {
        return `${new Date(text).toLocaleDateString()}  ${new Date(
          text
        ).toLocaleTimeString()}`;
      }
    },
    {
      title: "Generated By",
      dataIndex: "isTreeDriveGenerated",
      key: "isTreeDriveGenerated",
      render: (text, data) => {
        if (text) {
          return (
            <>
              <p>{data.generatedBy ? data.generatedBy : "EzyGo"}</p>
            </>
          );
        } else {
          return "ORC";
        }
      }
    },    
    {
      title: "Expiry Date",
      dataIndex: "expirayDate",
      key: "expirayDate",
      render: (text, record, index) => {
        return `${new Date(text).toLocaleDateString()}  ${new Date(
          text
        ).toLocaleTimeString()}`;
      }
    },
    {
      title: "Coupon Type",
      dataIndex: "coupanType",
      key: "coupanType"
    },
    {
      title: "country code",
      dataIndex: "countryCode",
      style:{width:"5px"},
      key: "countryCode",
      render: (text, record, index) => {
        console.log("-Text---",text)
        return `+${text?text:""}`;
      }
    },
    {
      title: "Phone No.",
      dataIndex: "customerPhoneNo",
      key: "customerPhoneNo",
      render: (text, record, index) => {
        return text;
      }
    },
    {
      title: "Coupon Status",
      key: "isActive",
      dataIndex: "isActive",

      render: (text, record, index) => {
        return (
          <>
            {text === true || text === false ? (
              <div>
                <input
                  type="radio"
                  name={index.toString() + "a"}
                  value={true}
                  checked={text == true}
                  onChange={(e) => changeStatus(record._id, true)}
                />
                <span> Active</span>
                <br></br>
                <input
                  type="radio"
                  name={index.toString() + "i"}
                  value={false}
                  checked={text == false}
                  onChange={(e) => changeStatus(record._id, false)}
                />
                <span> Inactive</span>
              </div>
            ) : (
              "Expired"
            )}
          </>
        );
      }
    },
    {
      title: "Resend",
      width: "5%",
      key: "Resend",
      render: (text, record, index) => (
        <Button
          onClick={() =>
            resendCoupon(
              record._id,
              record.couponCode,
              record.couponValue,
              record.assignedTo
            )
          }
        >
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
        </Button>
      )
    },

    {
      title: "Action",
      width: "5%",
      render: (text, record, index) => (
        <Button onClick={() => showConfirmationDialog(record._id)}>
          <i className="fa fa-trash" />
        </Button>
      ),
      key: "action"
    }
  ];

  return (
    <>
      <Table
      bordered
        loading={props.isLoading}
        columns={columns}
        dataSource={props.data.docs}
        footer={
          props.data.totalCouponValue
            ? () => (
                <div>
                  <span style={{ marginRight: "10%" }}>
                    <strong>{`Total Coupon Value: ${props.data.totalCouponValue} `}</strong>
                  </span>
                  <span></span>
                  <span>
                    <strong>
                      Total Remaining Value: {props.data.totalRemainingValue}
                    </strong>
                  </span>

                  <hr />
                </div>
              )
            : () => <div style={{ display: "none" }}></div>
        }
        pagination={{
          total: props.totalDocs,
          current: page,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
            props.changePage(
              props.date,
              props.branchId,
              page,
              pageSize,
              props.couponType
            );
          }
        }}
      ></Table>
      <CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        action={deleteCoupon}
      />
    </>
  );
};

export default CouponList;
