import React, { Component } from "react";
import endpoint from "../../helpers/Endpoint";
import {
  Row,
  Col,
  Button,
  DatePicker,
  PageHeader
} from "antd";
import moment from "moment";
import unpaidimg from "../../assets/Paid.77d24e64.png";
const { MonthPicker } = DatePicker;

export default class RestReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportspdf: [],
      selectedDate: new Date(),
      monthlyReportDate: ""
    };
  }

  componentDidMount() {
    let date = moment().date()._d;
    this.getRestReports();
  }
  getRestReports = (date) => {
    var hdr = localStorage.token;
    var branchId = this.props.match.params.id;
    // var postData = JSON.stringify({
    //   startDate: moment(date).set({
    //     hour: 4,
    //     minute: 0,
    //     second: 0
    //   }).format('YYYY-MM-DD HH:mm:ss'),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   }).format('YYYY-MM-DD HH:mm:ss'),
    //   branchId: branchId,
    //   isMonth: false
    // });

    var postData = JSON.stringify({
      startDate: moment(date),
      endDate: moment(date).add(1, "days"),
      branchId: branchId,
      isMonth: false
    });
    fetch(`${endpoint}/admin/singleadminreportweeklycancelled`, {
      method: "post",
      body: postData,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ reportspdf: result.data });
      });
  };
  chkdt = (dt) => {
    let date=dt
   // let startDate = moment(date).startOf("month").format('YYYY-MM-DD HH:mm:ss');
    // let endDate = moment(date).endOf("month").add(1, "days").format('YYYY-MM-DD HH:mm:ss');
    let startDate = moment(date).startOf("month").add(1, "days")
    let endDate = moment(date).endOf("month").add(1, "days")
    var hdr = localStorage.token;
    var branchId = this.props.match.params.id;
  // var postData = JSON.stringify({
    //   startDate: moment(startDate).set({
    //     hour: 4,
    //     minute: 0,
    //     second: 0
    //   }).format('YYYY-MM-DD HH:mm:ss'),
    //   endDate: moment(endDate).set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   }).format('YYYY-MM-DD HH:mm:ss'),
    //   branchId: branchId,
    //   isMonth: true
    // });

    var postData = JSON.stringify({
      startDate: moment(startDate),
      endDate: moment(endDate),
      branchId: branchId,
      isMonth: true
    });

    fetch(`${endpoint}/admin/singleadminreportweeklycancelled`, {
      method: "post",
      body: postData,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ reportspdf: result.data });
      });
  };
  sendMonthReports() {
    var branchId = this.props.match.params.id;
    var postData = {
      branchId: branchId,
      reportDate: this.state.monthlyReportDate
    };
  }

  downloadPdf = (path) => {
    var dtt = path.replace(/\//, "&").split("&")[1];
    var srr = dtt.split("/")[1];
    if (srr == "restReports") {
      path = dtt;
    } else {
      path = path;
    }
    var postData = {
      path: path
    };
    var hdr = localStorage.token;
    fetch(`${endpoint}/admin/getpresignedurla`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", result.data);
        } else {
          window.open(result, "_blank");
        }
      });
  };

  render() {
    return (
      <div style={{ boxShadow: "0px 0px 4px 2px #dbdbdb",borderRadius:10 }}>
        <PageHeader
          className="site-page-header"
          onBack={() => this.props.history.push(`/`)}
          title="Admin Reports Weekly Cancelled"
          subTitle={moment(this.state.selectedDate).format("YYYY-MMM-DD").toString()} >
        <div style={{display:"flex"}}>
        <MonthPicker
          onChange={(date, dateString) => this.chkdt(date)}
          placeholder="Select Month"
        />
        <DatePicker
            onChange={(date, dateString) => {
              this.getRestReports(date);
            }}
            format="YYYY-MM-DD"
            placeholder="Select day"
          />
        <Button onClick={() => this.sendMonthReports()} className="btnContainer">Send Report</Button>
        </div>
        </PageHeader>
        <Row style={{ padding: 25 }}>
          {this.state.reportspdf &&
            this.state.reportspdf.length > 0 &&
            this.state.reportspdf.map((rest) => {
              return (
                <Col span={4} style={{ marginBottom: 20}}>
                  <div className="report-box">
                    <a
                      onClick={() => this.downloadPdf(rest.pdf)}
                      id={rest.pdf}
                    >
                      <img src={unpaidimg}></img>
                    </a>
                  <p style={{
                        paddingLeft: '10px',
                        fontSize: "12px"
                      }}>
                    {rest.fileName}
                  </p>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
    );
  }
}

