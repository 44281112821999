import React from "react";
import { Card, Button, Spin } from "antd";

const ReserveTableComponent = (props) => {
  let commonStyle = {
    fontSize: "1.5rem",
    fontWeight: "600",
    marginBottom: "20px"
  };
  return (
    <div
      className="site-card-border-less-wrapper"
      style={{
        display: "flex",
        // width: "100vw",
        height: "50vh",
        alignContent: "center",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        borderRadius:10
      }}
    >
      <Card
        title="Reserve Table Price"
        bordered={false}
        style={{
          width: '100%',
        }}
      >
        {props.loading && (
          <div style={{padding:15}}>
            <Spin />
          </div>
        )}
        {props.price>-1&& !props.loading && (
          <h1 style={commonStyle}>
            {props.price}
            <span>Kr</span>
          </h1>
        )}

        {!props.loading  && (
          <p style={commonStyle}>Please set price</p>
        )}

        <div>
          <Button
            type="danger"
            onClick={() => props.onAddPriceHandler(true)}
            style={{ fontWeight: "600" }}
          >
            Add Price
          </Button>
        </div>
      </Card>
      <Card
        title="Coupon Extra % age"
        bordered={false}
        style={{
          width: '100%',
        }}
      >
        {props.loading && (
          <div style={{padding:15}}>
            <Spin />
          </div>
        )}
        {props.price>-1&& !props.loading && (
          <h1 style={commonStyle}>
            {props.extraCouponPercent}% age
          </h1>
        )}

        {!props.loading  && (
          <p style={commonStyle}>Please set percentage</p>
        )}

        <div>
          <Button
            type="danger"
            onClick={() => props.onAddPriceHandler(true)}
            style={{ fontWeight: "600" }}
          >
            Add % age
          </Button>
        </div>
      </Card>
    </div>
  );
};
export default ReserveTableComponent;
