import React, { useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import CustomPopupConfirm from "../popups/popupconfirm";
import CategoryForm from "./form";
const SuperCategories = (props) => {
  const [categories, setCategories] = useState([]);
  const [branch, setBranch] = useState({});
  const [page, setPage] = useState("list");
  const [isLoaded, setIsLoaded] = useState(false);
  const [category, setCategory] = useState({});
  const [isPopup, setPopup] = useState(false);
  const [catId, setCatId] = useState();

  useEffect(() => {
    getSuperCats();
  }, []);
  const getSuperCats = () => {
    const slug = props.match.params.slug;
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    setIsLoaded(true);
    fetch(`${endpoint}/api/v1/admin/branch/supperCategories`, {
      method: "POST",
      body: JSON.stringify({ id: slug }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        if (result) {
          setCategories(result.data.categories);
          setBranch(result.data.branch);
          setIsLoaded(false);
        }
      })
      .catch((error) => console.log(error));
  };
  const editForm = (item) => {
    setPage("edit");
    setCategory(item);
  };
  const addForm = () => {
    setCategory({});
    setPage("add");
  };
  const indexPage = () => {
    setCategory({});
    setPage("list");
    getSuperCats();
  };
  const showConformationDialog = (id) => {
    setCatId(id);
    setPopup(true);
  };
  const cancelConformationDialog = () => {
    setPopup(false);
  };
  const deleteSubCategory = (id) => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }

    fetch(`${endpoint}/api/v1/admin/branch/deleteSupCategories`, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        if (result) {
        }
        getSuperCats();
        setPopup(false);
      })
      .catch((error) => {
        console.log(error);
        setPopup(false);
      });
  };
  const addPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">
                <span className="icon-text">
                  <span className="icon" onClick={() => indexPage()}>
                    <i className="fas fa-arrow-left"></i>
                  </span>
                  <span>{branch.name} :Add Categories</span>
                </span>
              </h3>
              <div className="row">
                <div className="col-12">
                  <CategoryForm
                    category={category}
                    indexPage={indexPage}
                    branch={branch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const editPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">
                <span className="icon-text">
                  <span className="icon" onClick={() => indexPage()}>
                    <i className="fas fa-arrow-left"></i>
                  </span>
                  <span>{branch.name} :Edit Categories</span>
                </span>
              </h3>
              <div className="row">
                <div className="col-12">
                  <CategoryForm
                    category={category}
                    indexPage={indexPage}
                    branch={branch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const listPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 className="card-title">
                <span className="icon-text">
                  <span className="icon" onClick={() => props.history.goBack()}>
                    <i className="fas fa-arrow-left"></i>
                  </span>
                </span>
                  {branch.name} :Super Categories
              </h3>
                <button className="button is-success" onClick={() => addForm()}>
                  <i className="fa fa-plus"></i>&nbsp;{"Supper Category"}
                </button>
              </div>
              <div className="row">
                <div className="column">
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Category Type</th>
                        {branch &&
                          branch.taxes &&
                          branch.taxes.length > 0 &&
                          branch.taxes.map((tax) => {
                            return <th key={tax.taxName}>{tax.taxName}</th>;
                          })}

                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.length > 0 ? (
                        categories.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.sCatType ? item.sCatType : "-"}</td>
                              {branch &&
                                branch.taxes &&
                                branch.taxes.length > 0 &&
                                branch.taxes.map((tax, ix) => {
                                  let taxs =
                                    item.taxType && item.taxType.length > 0
                                      ? item.taxType.find(
                                          (obj) => obj.tax === tax._id
                                        )
                                      : "";
                                  return (
                                    <td key={ix + idx}>
                                      {taxs ? (
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                      {taxs && taxs.code ? taxs.code : ""}
                                    </td>
                                  );
                                })}

                              <td>
                                <button
                                  className="button is-primary"
                                  onClick={() => editForm(item)}
                                >
                                  <i className="fa fa-edit" />
                                </button>
                                <button
                                  className="button is-danger"
                                  onClick={() =>
                                    showConformationDialog(item._id)
                                  }
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="6" align="center">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {page === "list" ? listPage() : page === "edit" ? editPage() : addPage()}
      <CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        productId={catId}
        action={deleteSubCategory}
      />
    </>
  );
};

export default SuperCategories;
