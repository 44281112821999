import React, { useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import { notification, Icon, Row, Col, Input } from "antd";
import axios from "axios";

const EditTFLRange = (props) => {
  const [points, setPoints] = useState("");
  const [nok, setNok] = useState("");
  const [monthlyPer, setMonthlyPer] = useState("");
  const [validity, setValidity] = useState("");
  const token = localStorage.getItem("token");

  // Set initial values when the component mounts or Advertisement changes
  useEffect(() => {
    setPoints(props.Advertisement.giftCardAmt || ""); // Set default to empty string if undefined
    setNok(props.Advertisement.finalAmount || "");   // Set default to empty string if undefined
    setMonthlyPer(props.Advertisement.monthlyPer || "");   // Set default to empty string if undefined
    setValidity(props.Advertisement.validity || "");   // Set default to empty string if undefined
  }, [props.Advertisement]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const submitAddvertisement = async (e) => {
    e.preventDefault(); // Prevent default form submission
    let formData = {
      giftCardAmt: points,
      finalAmount: nok,
      monthlyPer: monthlyPer,
      validity: validity,
      id: props.Advertisement._id,
    };

    try {
      const res = await axios.post(
        `${endpoint}/api/v1/admin/branch/editTFLRange`,
        formData,
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );

      if (res.status === 200) {
        notification.open({
          message: "Your Range saved successfully!",
          icon: <Icon type="check-circle" style={{ color: "green" }} />,
          duration: 3,
        });
        props.indexPage(); // Call the parent index page function
      }
    } catch (error) {
      notification.error({
        message: "Error saving range",
        description: error.response?.data?.message || "An error occurred",
        duration: 3,
      });
    }
  };

  return (
    <div style={{ margin: "0 30%" }}>
      <form onSubmit={handleSubmit} className="form-horizontal box">
        <Row gutter={24} className="justify-content-center m-3">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="flex flex-column mb-2"
          >
            <p className="mb-0">Amount Paid (Kr.)</p>
            <Input
              onChange={(e) => setPoints(e.target.value)}
              value={points}
              type="number"
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="flex flex-column"
          >
            <p className="mb-0">Gift Card Amount (Kr.)</p>
            <Input
              onChange={(e) => setNok(e.target.value)}
              value={nok}
              type="number"
            />
          </Col>
        </Row>
        <Row gutter={24} className="justify-content-center m-3 mt-4" style={{paddingTop:"20px"}}>
      <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="flex flex-column"
        >
          <p className="mb-0">Monthly usage ( % )</p>{" "}
          <Input
            onChange={(e) => setMonthlyPer(e.target.value)}
            value={monthlyPer}
            // defaultValue={props.Advertisement.monthlyPer}
            // placeholder="Add NOK"
            type="Number"
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="flex flex-column"
        >
          <p className="mb-0">Validity ( Months )</p>{" "}
          <Input
            onChange={(e) => setValidity(e.target.value)}
            value={validity}
            // placeholder="Add NOK"
            type="Number"
          />
        </Col>
      </Row>
        <button
          type="submit"
          style={{ marginTop: "15px", width: "100%" }}
          className="button is-primary"
          onClick={submitAddvertisement}
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default EditTFLRange;
