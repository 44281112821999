import React, { Component, Fragment } from "react";
import Notification from "react-bulma-notification";
import RestaurantForm from "../restaurantForm/RestaurantForm";
import "../../../node_modules/react-bulma-notification/build/css/index.css";
import endpoint from "../../helpers/Endpoint";
import { Tag, Input, Tooltip, Icon, Button } from "antd";

class AddRestaurant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driveoutCharge: "",
      name: "",
      email: "",
      phoneNo: "",
      taxNo: "",
      line1: "",
      line2: "",
      city: "",
      pin: "",
      state: "",
      country: "",
      description: "",
      style: "",
      id: "",
      accountNumber: "",
      tdEnhPercentage: "",
      website: "",
      taccountantEmail: "",
      isRestaurantAvailable : "",
      restaurantEmail : "",
      devicesData: [],
      longitude: "",
      latitude: "",
      couponExpiresIn: "",
      merchantSerialNumber: "",
      merchantSerialNumberPos: "",
      tdDistance: "",
      tdServiceCharge: "",
      tdExtraPriceIncrease: "",
      tdFlatRateDriveOut: "",
      tdExtraRateDriveOut: "",
      tdStaticDriveOut: "",
      tdFlatDriveOutDistance: "",
      isWeekly: false,
      isVendorAllowed:false,
      isIngredientAllowed:true,
      tdDiscount: "",
      tdDiscountPickUp: "",
      reserveTablePermission: "",
      minimumOrder: "",
      machAccess:"",
      branchAddress:"",
      searchCity:"",
      branchType:"",
      range1Max:"",
      range1Min:"",
      discount1:"",
      range2Max:"",
      range2Min:"",
      discount2:"",
      range3Max:"",
      range3Min:"",
      discount3:"",
      range1MaxTA:"",
      range1MinTA:"",
      discount1TA:"",
      range2MaxTA:"",
      range2MinTA:"",
      discount2TA:"",
      range3MaxTA:"",
      range3MinTA:"",
      discount3TA:"",
      range4MaxTA:"",
      range4MinTA:"",
      discount4TA:"",
      range1MaxIDriveMyself:"",
    range1MinIDriveMyself:"",
    perKmFee1IDriveMyself:"",
    range2MaxIDriveMyself:"",
    range2MinIDriveMyself:"",
    perKmFee2IDriveMyself:"",
    range3MaxIDriveMyself:"",
    range3MinIDriveMyself:"",
    perKmFee3IDriveMyself:"",
    perDelFeeIDriveMyself:"",

    accountId : "",
    tidypayusername : "",
    tidypaypassword : "",
    tidypaypasswordweb:"",
    tidypaypassword6inch:"",
    // tidypaypermission : false,
    // dinteropermission : false,
    // verifonepermission: false,
    minDepositAllowed : 0,
    advanceDeposit : 0,
    minDiffAllowed:0,
    webpayment:"",
    machinepayment:"",
    tdTakeAwayDelay:0,
    tdDriveOutDelay:0,
    };
  }
  componentDidMount() {
    this.getdevices();
  }

  getdevices = () => {
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    const token = localStorage.getItem("token");
    fetch(`${endpoint}/admin/getinactivedevices`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result.data);
        } else {
          this.setState({ devicesData: result.data, isLoaded: true });
        }
      })
      .catch((error) => console.log(error));
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  addRestaurant = (
    e,
    editing,
    name,
    email,
    phoneNo,
    taxNo,
    line1,
    line2,
    city,
    pin,
    state,
    country,
    description,
    tflamount,
    accountNumber,
    tdEnhPercentage,
    id,
    website,
    driveoutCharge,
    taccountantEmail,
    isRestaurantAvailable,
    restaurantEmail,
    kitchenpermission,
    barpermission,
    tippermission,
    deliverypermission,
    pickuppermission,
    deviceId,
    longitude,
    latitude,
    couponExpiresIn,
    couponPermissionChange,
    vippsPermissionChange,
    merchantSerialNumber,
    merchantSerialNumberPos,
    isTreeDriveAllowed,
    tdDiscountPermission,
     isNewReports,
    isWebOpperKonto,
    isDriveMyself,
    isTdvipps,
    isFindDriver,
    isTreeDriveDriveOutAllowed,
    isTreeDrivePickUpAllowed,
    tdDistance,
    tdServiceCharge,
    tdExtraPriceIncrease,
    tdFlatRateDriveOut,
    tdExtraRateDriveOut,
    tdStaticDriveOut,
    tdFlatDriveOutDistance,
    isWeekly,
    isVendorAllowed,
    isIngredientAllowed,
    tdDiscount,
    tdDiscountPickUp,
    reserveTablePermission,
    minimumOrder,
    machAccess,
    branchAddress,
    searchCity,
    branchType,
    range1Max,
    range1Min,
    discount1,
    range2Max,
    range2Min,
    discount2,
    range3Max,
    range3Min,
    discount3,
    range1MaxTA,
    range1MinTA,
    discount1TA,
    range2MaxTA,
    range2MinTA,
    discount2TA,
    range3MaxTA,
    range3MinTA,
    discount3TA,
    range4MaxTA,
    range4MinTA,
    discount4TA,
    range1MaxIDriveMyself,
    range1MinIDriveMyself,
    perKmFee1IDriveMyself,
    range2MaxIDriveMyself,
    range2MinIDriveMyself,
    perKmFee2IDriveMyself,
    range3MaxIDriveMyself,
    range3MinIDriveMyself,
    perKmFee3IDriveMyself,
    perDelFeeIDriveMyself,

    accountId,
    tidypayusername,
    tidypaypassword,
    tidypaypasswordweb,
    tidypaypassword6inch,
    // tidypaypermission,
    // dinteropermission,
    // verifonepermission,
    minDepositAllowed,
    advanceDeposit,
    minDiffAllowed,
    webpayment,
    machinepayment,
    tdTakeAwayDelay,
    tdDriveOutDelay,
  ) => {
    e.preventDefault();
    if (!editing) {
      function handleErrors(result) {
        if (!result.ok) {
          throw Error(result.status);
        }
        return result;
      }
      fetch(`${endpoint}/admin/addrestaurant`, {
        method: "POST",
        body: JSON.stringify({
          name,
          phoneNo,
          email,
          taxNo,
          line1,
          line2,
          city,
          pin,
          state,
          country,
          tflamount,
          description,
          accountNumber,
          tdEnhPercentage,
          website,
          driveoutCharge,
          taccountantEmail,
          isRestaurantAvailable,
          restaurantEmail,
          kitchenpermission,
          barpermission,
          tippermission,
          deliverypermission,
          pickuppermission,
          devices: deviceId,
          longitude,
          latitude,
          couponExpiresIn,
          couponPermissionChange,
          vippsPermissionChange,
          merchantSerialNumber,
          merchantSerialNumberPos,
          isTreeDriveAllowed,
          tdDiscountPermission,
          isNewReports,
          isWebOpperKonto,
          isDriveMyself,
          isTdvipps,
    isFindDriver,
          isTreeDriveDriveOutAllowed,
          isTreeDrivePickUpAllowed,
          tdDistance,
          tdServiceCharge,
          tdExtraPriceIncrease,
          tdFlatRateDriveOut,
          tdExtraRateDriveOut,
          tdStaticDriveOut,
          tdFlatDriveOutDistance,
          isWeekly,
          isVendorAllowed,
          isIngredientAllowed,
          tdDiscount,
          tdDiscountPickUp,
          reserveTablePermission,
          minimumOrder,
          machAccess,
          branchAddress,
          searchCity,
          branchType,
          range1Max,
          range1Min,
          discount1,
          range2Max,
          range2Min,
          discount2,
          range3Max,
          range3Min,
          discount3,
          range1MaxTA,
          range1MinTA,
          discount1TA,
          range2MaxTA,
          range2MinTA,
          discount2TA,
          range3MaxTA,
          range3MinTA,
          discount3TA,
          range4MaxTA,
          range4MinTA,
          discount4TA,
          range1MaxIDriveMyself,
    range1MinIDriveMyself,
    perKmFee1IDriveMyself,
    range2MaxIDriveMyself,
    range2MinIDriveMyself,
    perKmFee2IDriveMyself,
    range3MaxIDriveMyself,
    range3MinIDriveMyself,
    perKmFee3IDriveMyself,
    perDelFeeIDriveMyself,

    accountId,
    tidypayusername,
    tidypaypassword,
    tidypaypasswordweb,
    tidypaypassword6inch,
    // tidypaypermission,
    // dinteropermission,
    // verifonepermission,
    minDepositAllowed,
    advanceDeposit,
    minDiffAllowed,
    webpayment,
    machinepayment,
    tdTakeAwayDelay,
    tdDriveOutDelay,
        }),
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(handleErrors)
        .then((result) => result.json())
        .then((result) => {
          if (result.status === "failure") {
            console.log("errors");
            this.openNotification("error", result.data);
          } else {
            this.setState({ style: "is-active", id: result.data._id });
            this.openNotification("success", result.message);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  handleIconChange = (e) => {
    const { id } = this.state;
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("logo", file);
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/restaurant/${id}/logo`, {
      method: "POST",
      body: formData,
      headers: {
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
          this.openNotification("error", result.data);
        } else {
          this.setState({ style: "" });
          this.props.history.push("/");
          this.openNotification("success", result.message);
        }
      })
      .catch((error) => console.log(error));
  };

  closeModal = () => {
    this.setState({ style: "" });
  };

  render() {
    const { style } = this.state;
    return (
      <Fragment>
        <RestaurantForm
          devicesData={this.state.devicesData}
          editing={false}
          saveRestaurant={(
            e,
            editing,
            name,
            email,
            phoneNo,
            taxNo,
            line1,
            line2,
            city,
            pin,
            state,
            country,
            description,
            tflamount,
            accountNumber,
            tdEnhPercentage,
            id,
            website,
            driveoutCharge,
            taccountantEmail,
            isRestaurantAvailable,
            restaurantEmail,
            kitchenpermission,
            barpermission,
            tippermission,
            deliverypermission,
            pickuppermission,
            deviceId,
            longitude,
            latitude,
            couponExpiresIn,
            couponPermissionChange,
            vippsPermissionChange,
            merchantSerialNumber,
            merchantSerialNumberPos,
            isTreeDriveAllowed,
            tdDiscountPermission,
            isNewReports,
            isWebOpperKonto,
            isDriveMyself,
            isTdvipps,
            isFindDriver,
            isTreeDriveDriveOutAllowed,
            isTreeDrivePickUpAllowed,
            tdDistance,
            tdServiceCharge,
            tdExtraPriceIncrease,
            tdFlatRateDriveOut,
            tdExtraRateDriveOut,
            tdStaticDriveOut,
            tdFlatDriveOutDistance,
            isWeekly,
            isVendorAllowed,
            isIngredientAllowed,
            tdDiscount,
            tdDiscountPickUp,
            reserveTablePermission,
            minimumOrder,
            machAccess,
            branchAddress,
            searchCity,
            branchType,
            range1Max,
            range1Min,
            discount1,
            range2Max,
            range2Min,
            discount2,
            range3Max,
            range3Min,
            discount3,
            range1MaxTA,
            range1MinTA,
            discount1TA,
            range2MaxTA,
            range2MinTA,
            discount2TA,
            range3MaxTA,
            range3MinTA,
            discount3TA,
            range4MaxTA,
            range4MinTA,
            discount4TA,
            range1MaxIDriveMyself,
    range1MinIDriveMyself,
    perKmFee1IDriveMyself,
    range2MaxIDriveMyself,
    range2MinIDriveMyself,
    perKmFee2IDriveMyself,
    range3MaxIDriveMyself,
    range3MinIDriveMyself,
    perKmFee3IDriveMyself,
    perDelFeeIDriveMyself,
    accountId,
    tidypayusername,
    tidypaypassword,
    tidypaypasswordweb,
    tidypaypassword6inch,
    // tidypaypermission,
    // dinteropermission,
    // verifonepermission,
    minDepositAllowed,
    advanceDeposit,
    minDiffAllowed,
    webpayment,
    machinepayment,
    tdTakeAwayDelay,
    tdDriveOutDelay
          ) => {
            console.log("latitude inside save restaurant", longitude);
            console.log("longitude inside save restaurant", latitude);
            console.log(
              "couponExpires in inside save restaurant",
              couponExpiresIn
            );

            this.addRestaurant(
              e,
              editing,
              name,
              email,
              phoneNo,
              taxNo,
              line1,
              line2,
              city,
              pin,
              state,
              country,
              description,
              tflamount,
              accountNumber,
              tdEnhPercentage,
              id,
              website,
              driveoutCharge,
              taccountantEmail,
              isRestaurantAvailable,
              restaurantEmail,
              kitchenpermission,
              barpermission,
              tippermission,
              deliverypermission,
              pickuppermission,
              deviceId,
              longitude,
              latitude,
              couponExpiresIn,
              couponPermissionChange,
              vippsPermissionChange,
              merchantSerialNumber,
              merchantSerialNumberPos,
              isTreeDriveAllowed,
              tdDiscountPermission,
              isNewReports,
              isWebOpperKonto,
              isDriveMyself,
              isTdvipps,
              isFindDriver,
              isTreeDriveDriveOutAllowed,
              isTreeDrivePickUpAllowed,
              tdDistance,
              tdServiceCharge,
              tdExtraPriceIncrease,
              tdFlatRateDriveOut,
              tdExtraRateDriveOut,
              tdStaticDriveOut,
              tdFlatDriveOutDistance,
              isWeekly,
              isVendorAllowed,
              isIngredientAllowed,
              tdDiscount,
              tdDiscountPickUp,
              reserveTablePermission,
              minimumOrder,
              machAccess,
              branchAddress,
              searchCity,
              branchType,
              range1Max,
              range1Min,
              discount1,
              range2Max,
              range2Min,
              discount2,
              range3Max,
              range3Min,
              discount3,
              range1MaxTA,
              range1MinTA,
              discount1TA,
              range2MaxTA,
              range2MinTA,
              discount2TA,
              range3MaxTA,
              range3MinTA,
              discount3TA,
              range4MaxTA,
              range4MinTA,
              discount4TA,
              range1MaxIDriveMyself,
    range1MinIDriveMyself,
    perKmFee1IDriveMyself,
    range2MaxIDriveMyself,
    range2MinIDriveMyself,
    perKmFee2IDriveMyself,
    range3MaxIDriveMyself,
    range3MinIDriveMyself,
    perKmFee3IDriveMyself,
    perDelFeeIDriveMyself,
    accountId,
    tidypayusername,
    tidypaypassword,
    tidypaypasswordweb,
    tidypaypassword6inch,
    minDepositAllowed,
    advanceDeposit,
    minDiffAllowed,
    webpayment,
    machinepayment,
    tdTakeAwayDelay,
    tdDriveOutDelay,
            );
          }}
        />
        <div className={`modal ${style}`}>
          <div className="modal-background" />
          <div className="modal-content" style={{ zIndex: 1 }}>
            <div className="tile is-parent">
              <article className="tile is-child notification ">
                <div className="content">
                  <div className="file">
                    <label className="file-label">
                      <input
                        style={{ color: "#333" }}
                        className="file-input"
                        type="file"
                        name="icon"
                        onChange={this.handleIconChange}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload" />
                        </span>
                        <span className="file-label">Choose a file…</span>
                      </span>
                    </label>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div
            onClick={this.closeModal}
            style={{
              minWidth: "100%",
              minHeight: "100%",
              position: "absolute"
            }}
          >
            <button className="modal-close is-large" aria-label="close" />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AddRestaurant;
