import { Divider } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

const OppgjørTables = () => {
  return (
    <>
      <Divider />
      <aside className="menu">
        <ul className="menu-list">
          <li>
            <NavLink exact to="/oppgjør" activeClassName="is-active">
              Oppgjør
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/oppgjør-2" activeClassName="is-active">
              Oppgjør2
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/oppgjørOld" activeClassName="is-active">
              oppgjørOldTest
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/vendoroppgjør1" activeClassName="is-active">
              vendor Oppgjr old
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/vendorOppgjrNew" activeClassName="is-active">
              vendor Oppgjr New
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/retaileroppgjør1" activeClassName="is-active">
              retailer Oppgjr
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/retailerOppgjrNew" activeClassName="is-active">
              retailer Oppgjr New
            </NavLink>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default OppgjørTables;
