import React, { useState } from "react";
import { CustomModalComponent } from "../popups/ContentModal";

import { Button, Checkbox, Form, Input } from "antd";
import endpoint from "../../helpers/Endpoint";

const AddReserePriceCompoenet = (props) => {
  const [price, setPrice] = useState(props.priceSat);
  const [couponPercent, setCouponPercent] = useState(props.couponPercent);
  const [error, setPriceError] = useState();
  let closePriceModal = () => {
    props.onAddPriceHandler(false);
  };

  let addPriceHandeler = async () => {
    console.log(price);
    if (price.toString().trim() == "") {
      setPriceError("Please set Price");
      return;
    }

    console.log("hello");
    try {
      const token = localStorage.getItem("token");

      let response = await fetch(
        `${endpoint}/api/v1/admin/branch/savereservetableprice`,
        {
          method: "POST",
          body: JSON.stringify({
            price: price,
            couponPercent:couponPercent
          }),
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
          }
        }
      );

      if (!response.ok) {
        throw new Error("something wrong with saving price");
      }
      if (response.status == 200) {
        props.reserveTableCallback();
      }
      closePriceModal();
    } catch (error) {
      console.log(error);
    }
  };
  let onPriceChangeHandler = (event) => {
    setPrice(event.target.value);
  };

  return (
    <CustomModalComponent
      closePriceModal={closePriceModal}
      isShowPriceCompoent={props.isShowPriceCompoent}
      addPriceHandeler={addPriceHandeler}
      title="SET PRICES"
    >
      <label>
        Reserve Price
      </label>
      <Input
        placeholder="Reserve Price"
        type="number"
        // min={0}
        value={price}
        onChange={onPriceChangeHandler}
      />

      <label>
      Extra Tfl Percentage
      </label>
      <Input
        placeholder="Extra Tfl Percentage"
        type="number"
        // min={0}
        value={couponPercent}
        onChange={(e)=>(setCouponPercent(e.target.value))}
      />
      {error && <div style={{ marginTop: "5px" }}>{error}</div>}
    </CustomModalComponent>
  );
};
export default AddReserePriceCompoenet;
