import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const ListIcons = () => {
  return (
    <div style={{
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      borderRadius:10,
      height:"70vh"
    }}>
      <div className="tile is-parent admin-icons">
      <ul className="menu-list2">
        <li>
          <NavLink exact to="/add-icons" activeClassName="is-active">
            Restaurant Icons
          </NavLink>
        </li>

        <li>
          <NavLink exact to="/vendor-icons" activeClassName="is-active">
            Vendor/Retailer Icons
          </NavLink>
        </li>

        <li>
          <NavLink exact to="/barber-icons" activeClassName="is-active">
            Barber Icons
          </NavLink>
        </li>
      </ul>
    </div>
    </div>
    
  );
};

export default ListIcons;
