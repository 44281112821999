import React, { useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import { Radio, Select, Switch, notification, Icon, DatePicker, Input } from "antd";
import "./Advertisement.css";
import axios from "axios";
import moment from 'moment';

const EditAdvertisement = (props) => {
    const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [Id, setId] = useState();
  const [isAddOn, setIsAddOn] = useState(true);
  const [validation , setValidation] = useState(false);

  const [isAdvertisementOn, setIsAdvertisementOn] =  useState(false);

let SDate = "";
let eDate = "";
//let isAdvertisementOn = "";

useEffect(()=>{
  let aa = (props.Advertisement.isAdvertisementOn == 'true') ? true : false;
  console.log('-----addddddd--', aa);
  setIsAdvertisementOn(aa);
},[])

  useEffect(()=>{
    console.log("selected advertisement", props.Advertisement);
    SDate =  props.Advertisement.timePeriod.startDate;
    eDate = props.Advertisement.timePeriod.endDate
  //  isAdvertisementOn = props.Advertisement.isAdvertisementOn;
    setId(props.Advertisement._id);
    console.log("SDate", SDate)
  })

  const handleSubmit = (event) => {
    event.preventDefault();
    // let isError = false;
    // if (name.trim() === "") {
    //   isError = true;
    //   errorObj.nameError = "Name is required";
    // }
    // if (sCatType === "") {
    //   isError = true;
    //   errorObj.sCatTypeError = "Category Type is required";
    // }
    // if (taxType.length > 0) {
    //   for (let i = 0; i < taxType.length; i++) {
    //     if (
    //       taxType[i].code &&
    //       taxType[i].code.trim() !== "" &&
    //       isNaN(taxType[i].code)
    //     ) {
    //       isError = true;
    //       errorObj.CodeError = "Code Should be in number";
    //     }
    //   }
    // }
    // if (isError) {
    //   setErrors(errorObj);
    // } else {
    //   saveCategory();
    // }
  };
  const submitAddvertisement = async (data) => {
    console.log("validation", validation)
  
    const token = localStorage.getItem("token");
        const res = await axios.post(
            `${endpoint}/api/v1/admin/branch/updateAdvertisement`,
            data,      
            {
              headers: {
                // "Content-Type": "application/json",
                "x-auth-token": token,
              },
            }
          );
    
          if(res.status == 200){
            props.indexPage();
          }
    

  };

  const editAddvertisement = async () =>{
    if(Id !== "" && (startDate !== "" || SDate !== "") && (endDate !== "" || eDate !=="") && (isAddOn !=="")){
        console.log("gdfjhkdj", SDate, eDate , isAdvertisementOn)
        let payload = {};
        payload._id= Id
        payload.startDate = startDate ? startDate : SDate;
        payload.endDate = endDate ? endDate : eDate;
        payload.isAdvertisementOn = isAdvertisementOn;
        console.log("PAYLOAD", payload)
        submitAddvertisement(payload)
    }else{
        notification.open({
            message: "Select Again !",
            duration: 3,
          });
    }
  }

  function onChange2(checked) {
    console.log(`switch to ${checked}`);
    setIsAdvertisementOn(checked)
    setIsAddOn(checked);
  }


  return (
    <div style={{ margin: "0 30%" }}>
    <form onSubmit={handleSubmit} className="form-horizontal box">
      <div className="field">
        <label className="label">Branch Type : {"  "}{
          props.Advertisement.branchType == "rest" ? "Restaurant" :
          props.Advertisement.branchType == "vendor" ? "Vendor":
          props.Advertisement.branchType == "retailer" ? "Retailer":
          ""
        }</label>
        <div className="control">
          <div className="field">
            {/* <Radio.Group >
              <Radio value={"restaurant"}>Restaurant</Radio>
              <Radio value={"vendor"}>Vendor</Radio>
              <Radio value={"retailer"}>Retailer</Radio>
            </Radio.Group> */}
          </div>
        </div>
      </div>
      <div className="field">
        <label className="label">Branch Name :  {props.Advertisement.branchName}</label>
        {/* <div className="control">
          <div style={{ width: "100%" }} className="select1">
            <Select
              required
              name="restaurant"
              placeholder="Please select"
              style={{ width: "100%" }}
              value={props.Advertisement.branchName}
              //onChange={(e) => branchNameSelected(e)}
            >
            </Select>
          </div>
        </div> */}
      </div>
      <div className="field">
        <label className="label">Content Type: {props.Advertisement.contentType}</label>
        {/* <Radio.Group>
          <Radio value={"picture"}>Picture</Radio>
          <Radio value={"gif"}>Gif</Radio>
          <Radio value={"video"}>Video</Radio>
        </Radio.Group> */}
      </div>
      {/* <div style={{marginBottom:"20px"}}>
        <input
          className="file-input"
          type="file"
          name="icon"
          // accept="image/*"
         // value={props.Advertisement.url}
        />
      </div> */}
      <div className="field"  style={{marginTop:"40px"}}>
        <div className="bordered-special1">
          <label className="label">Keep Advertisement On</label>
          <div className="field-label is-normal"></div>
          <div className="field">
            <div className="control">
              <Switch
                onChange={onChange2}
            //   defaultChecked={props.Advertisement.isAdvertisementOn}
                defaultValue={isAdvertisementOn}
                checked = {isAdvertisementOn}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <label className="label">Or select Date : </label>
      </div>
      <div className="d-flex field">
        <DatePicker
        defaultValue={moment(props.Advertisement.timePeriod.startDate)}
          onChange={(date, dateString) => {
            let setDate = new Date(date);
            console.log("date", { setDate, dateString });
            setStartDate(setDate);
          }}
          format="YYYY-MM-DD"
         // placeholder="From"
        />
        <DatePicker
       defaultValue={moment(props.Advertisement.timePeriod.endDate)}
          onChange={(date, dateString) => {
            let setDate = new Date(date);
            console.log("date", { setDate, dateString });
            setEndDate(setDate);
          }}
          format="YYYY-MM-DD"
         // placeholder="To"
        />
      </div>
     
      {/* { FileError.length > 0 ? <p className= "errorMsg">{FileError} </p> : ""} */}
      <button
        type="submit"
        style={{ marginTop: "15px", width: "100%" }}
        className="button is-primary"
        onClick={editAddvertisement}
      >
        Update
      </button>
    </form>
  </div>
  )
}

export default EditAdvertisement
