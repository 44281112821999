import React, { useCallback, useEffect, useState } from "react";
import ReserveTableComponent from "./ReserveTableComponent";
import AddReserePriceCompoenet from "./AddReserveTablePriceComponent";
import endpoint from "../../helpers/Endpoint";
const ReserveTablePrice = () => {
  const [isShowPriceCompoent, setShowPriceCompoent] = useState(false);
  const [price, setPrice] = useState(0);
  const [extraCouponPercent, setExtraCouponPercent] = useState(0);
  const [loading, setIsLoading] = useState(false);
  let onAddPriceHandler = (val) => {
    setShowPriceCompoent(val);
  };

  useEffect(() => {
    reserveTableCallback();
  }, []);

  let reserveTableCallback = useCallback(() => {
    async function getReserVePrice() {
      try {
        setIsLoading(true);
        const token = localStorage.getItem("token");

        let response = await fetch(
          `${endpoint}/api/v1/admin/branch/reservetableprice`,
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "Content-Type": "application/json"
            }
          }
        );
        setIsLoading(false);
        if (!response.ok) {
          throw new Error("Something went wrong getting price");
        }

        let res = await response.json();
        setPrice(res.data.price);
        setExtraCouponPercent(res.data.extraCouponPercent)
      } catch (error) {
        setPrice(0);
        setExtraCouponPercent(0)
      }
    }
    getReserVePrice();
  }, []);

  return (
    <React.Fragment>
      {isShowPriceCompoent && (
        <AddReserePriceCompoenet
          isShowPriceCompoent={isShowPriceCompoent}
          onAddPriceHandler={onAddPriceHandler}
          reserveTableCallback={reserveTableCallback}
          priceSat={price}
          couponPercent={extraCouponPercent}
        ></AddReserePriceCompoenet>
      )}
      <ReserveTableComponent
        loading={loading}
        price={price}
        extraCouponPercent={extraCouponPercent}
        onAddPriceHandler={onAddPriceHandler}
      ></ReserveTableComponent>
    </React.Fragment>
  );
};

export default ReserveTablePrice;
