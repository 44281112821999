import React, { useState, useEffect } from "react";
import endpoint from "../../helpers/Endpoint";
import AddTFLRange from "./AddTFLRange";
import s3url from "../../helpers/s3";
import axios from "axios";
import NotWorkingLogo from "../../assets/1.png";
import WorkingLogo from "../../assets/2.png";
import { notification, Icon, Popover, Tag, Col, Row } from "antd";
import CustomPopupConfirm from "./CustomPopupConfirm";
import moment from "moment";
import EditTFLRange from "./EditTFLRange";

const text = <span>Title</span>;
const content = (
  <div>
    <p>Content</p>
    <p>Content</p>
  </div>
);
const buttonWidth = 70;

const TFLRange = (props) => {
  const [Advertisement, setAdvertisement] = useState([]);
  const [page, setPage] = useState("list");
  const [addId, setAddId] = useState();
  const [isPopup, setPopup] = useState(false);
  const [adddId, setAdddId] = useState("");
  useEffect(() => {
    getTFLRangeList();
  }, []);

  const getTFLRangeList = async () => {
    try {
      let result = await fetch(`${endpoint}/api/v1/admin/branch/getTFLRange`, {
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((result) => result.json())
        .then((result) => {
          if (result.status == 500) {
            console.log("THERE IS A FAULURE ");
          }
          if (result) {
            //console.log("data === ", result)

            setAdvertisement(result.data);
          }
        });
    } catch (error) {
      console.log("something went wrong");
    }
  };

  const addForm = () => {
    setAdvertisement({});
    setPage("add");
  };
  const backgroundForm = () => {
    setAdvertisement({});
    setPage("background");
  };
  const editForm = (item) => {
    setPage("edit");
    setAdvertisement(item);
  };

  const indexPage = () => {
    //setAdvertisement({});
    setPage("list");
    getTFLRangeList();
    //getSuperCats();
  };

  const deleteApi = async () => {
    console.log("id == ", addId);

    try {
      const res = await axios.post(
        `${endpoint}/api/v1/admin/branch/deleteTFLRange`,
        { _id: addId }
      );

      if (res.data.status == "success") {
        setPopup(false);
        notification.open({
          message: "Your advertisement deleted successfully!",
          icon: <Icon type="check-circle" style={{ color: "green" }} />,
          duration: 2,
        });
        getTFLRangeList();
      }
    } catch (error) {
      console.log("something went wrong");
      setPopup(false);
    }
  };

  const showConformationDialog = (id) => {
    setAddId(id);
    setPopup(true);
    //deleteAddvert(id);
    //setPopup(true);
    //deleteApi(id)
  };
  const cancelConformationDialog = () => {
    setPopup(false);
  };

  const backbtn = () => {
    props.history.goBack();
    getTFLRangeList();
  };

  //   function myFunc(url) {
  //     window.location.replace(`https://pos-stockholm-bucket.s3.eu-north-1.amazonaws.com/${url}`);
  // }
  const addPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">
                <span className="icon-text">
                  <span className="icon">
                    <i
                      className="fas fa-arrow-left"
                      onClick={() => indexPage()}
                    ></i>
                  </span>
                  <span>ADD TFL GIFT CARD RANGE</span>
                </span>
              </h3>
              <div className="row">
                <div className="col-12">
                  <AddTFLRange indexPage={indexPage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const editPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">
                <span className="icon-text">
                  <span className="icon" onClick={() => indexPage()}>
                    <i className="fas fa-arrow-left"></i>
                  </span>
                  <span>Edit Range</span>
                </span>
              </h3>
              <div className="row">
                <div className="col-12">
                  <EditTFLRange
                    Advertisement={Advertisement}
                    indexPage={indexPage}
                    // branch={branch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const backgroundPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">
                <span className="icon-text">
                  <span className="icon" onClick={() => indexPage()}>
                    <i className="fas fa-arrow-left"></i>
                  </span>
                  <span>Background for Advertisement</span>
                </span>
              </h3>
            </div>
          </div>
        </div>
      </>
    );
  };

  const listPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <Row gutter={8}>
                <Col span={20}>
                  <h3 className="card-title">
                    <span className="icon-text">
                      <span className="icon" onClick={() => backbtn()}>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                    </span>
                    TFL GIFT CARD RANGE
                  </h3>
                </Col>
                <Col span={4} className="mr-2">
                  <button
                    className="button is-success"
                    onClick={() => addForm()}
                  >
                    <i className="fa fa-plus"></i>&nbsp;{"Range"}
                  </button>
                </Col>
              </Row>
              <div className="row">
                <div className="column">
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Amount Paid (Kr.)</th>
                        <th>Gift Card Amount (Kr.)</th>
                        <th>Monthly Usage % Of Gift Card </th>
                        <th>Validity (Months) </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Advertisement.length > 0 ? (
                        Advertisement.map((item, idx) => {
                          let today = new Date();
                          return (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td>{item.giftCardAmt}</td>
                              <td>{item.finalAmount}</td>
                              <td>{item.monthlyPer}</td>
                              <td>
                                {item.validity}{" "}
                                {item.validity === 1 ? "Month" : "Months"}
                              </td>

                              <td>
                                <button
                                  className="button is-primary "
                                  onClick={() => editForm(item)}
                                >
                                  <i className="fa fa-edit" />
                                </button>
                                <button
                                  className="button is-danger"
                                  onClick={() =>
                                    showConformationDialog(item._id)
                                  }
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="6" align="center">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {page === "list"
        ? listPage()
        : page === "edit"
        ? editPage()
        : page == "background"
        ? backgroundPage()
        : addPage()}
      <CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        productId={addId}
        action={deleteApi}
      />
    </>
  );
};

export default TFLRange;
