import { Divider } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import { Radio } from "antd";
import Notification from "react-bulma-notification";
const AdminPayment = () => {
  let token = localStorage.getItem("token");

  const [data, setData] = useState({
    getDetailsORC: {
      accountId: "",
      terminalId: "",
      tidypayusername: "",
      tidypaypassword: "",
      tidypaypasswordweb: "",
      type: "ORC",
      paymentMethod: "",
    },
  });

  const [value, setValue] = useState({
    getDetailsTD: {
      accountId: "",
      terminalId: "",
      tidypayusername: "",
      tidypaypassword: "",
      tidypaypasswordweb: "",
      type: "TD",
      paymentMethod: "",
    },
  });

  const [admin, setAdmin] = useState({
    email: "",
    password: "",
  });

  const selectedPaymentMethod = async (e) => {
    setData({
      getDetailsORC: { ...data.getDetailsORC, paymentMethod: e.target.value },
    });
    setValue({
      getDetailsTD: { ...value.getDetailsTD, paymentMethod: e.target.value },
    });
  };

  const handleChange = (e) => {
    setData({
      getDetailsORC: { ...data.getDetailsORC, [e.target.name]: e.target.value },
    });
  };

  const handleChange2 = (e) => {
    setValue({
      getDetailsTD: { ...value.getDetailsTD, [e.target.name]: e.target.value },
    });
  };

  const handleChangePass = (e) => {
    setAdmin({
      ...admin,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    Axios.get(`${endpoint}/admin/admin-details`).then((res) => {
      delete data._id;
      delete data.createdAt;
      delete data.updatedAt;

      setData(res?.data?.data);
      setValue(res?.data?.data);
    });

    Axios.get(`${endpoint}/api/v1/employee/ansatte/employee-admin`).then(
      (res) => {
        setAdmin({ ...admin, email: res?.data?.data?.email });
      }
    );
  }, []);

  const openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };
  const saveEmployeeAdmin = async () => {
   let res= await Axios.post(
      `${endpoint}/api/v1/employee/ansatte/employee-admin`,
      { ...admin, branchAdmin: true },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );

    console.log("res",res.data.message)
    openNotification("error",res.data.message)
  };

  const addDetailsFunc = async (check) => {
    let datas = data.getDetailsORC;
    if (check == "value") {
      datas = value.getDetailsTD;
    }
    delete datas._id;

    await Axios.post(
      `${endpoint}/admin/admin-details`,
      { datas: datas },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  return (
    <>
      <div
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          padding: 25,
          borderRadius:10
        }}
      >
        <Divider>
          <label className="label">Admin Super User</label>
          
        </Divider>
        <div className="list-bullets">
           
              <li>unique email is required for super admin as cannot use already existing employee email.</li>
              <li>If you change superadmin email then earlier email wont work as new email will replace it.</li>
            
          </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Admin Email For Restaurants</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  required
                  className="input"
                  type="email"
                  name="email"
                  placeholder="Admin Email For Restaurants"
                  value={admin.email}
                  // onChange={(e) => handleChangePass(e)}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope" />
                </span>
              </p>
            </div>
            <div className="field-label is-normal">
              <label className="label">Admin Email Password</label>
            </div>
            <div className="field ">
              <p className="control is-expanded has-icons-left">
                <input
                  required
                  className="input"
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  value={admin.password}
                  onChange={(e) => handleChangePass(e)}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-key" />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label" />
          <div className="field-body">
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary"
                  onClick={() => saveEmployeeAdmin()}
                >
                  Save Restaurants Admin
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------------| TidyPay ORC |------------------------------ */}
        <div style={{ margin: "7vh 0" }}>
          <Divider>
            <label className="label">Admin Payment For ORC</label>
          </Divider>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Account Id</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    required
                    className="input"
                    name="accountId"
                    placeholder="accountId"
                    value={data.getDetailsORC.accountId}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-phone" />
                  </span>
                </p>
              </div>
              <div className="field-label is-normal">
                <label className="label">Terminal Id</label>
              </div>
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    required
                    className="input"
                    name="terminalId"
                    placeholder="terminalId"
                    value={data.getDetailsORC.terminalId}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-file-alt" />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Tidypay Username</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    required
                    className="input"
                    name="tidypayusername"
                    placeholder="tidypayusername"
                    value={data.getDetailsORC.tidypayusername}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-phone" />
                  </span>
                </p>
              </div>
              <div className="field-label is-normal">
                <label className="label">Tidypay Password</label>
              </div>
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    required
                    className="input"
                    name="tidypaypassword"
                    placeholder="tidypaypassword"
                    value={data.getDetailsORC.tidypaypassword}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-file-alt" />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Tidypay Password Web</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    required
                    className="input"
                    name="tidypaypasswordweb"
                    placeholder="tidypaypasswordweb"
                    value={data.getDetailsORC.tidypaypasswordweb}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-phone" />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label" />
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button
                    className="button is-primary"
                    onClick={() => addDetailsFunc("data")}
                  >
                    Save Restaurant
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field-body">
          <div className="field-label is-normal">
            <label className="label" style={{ textAlign: "left" }}>
              Payment Method For Reserve Table (If No Admin Payment Exists)
            </label>
          </div>
          <div className="field">
            <Radio.Group
              className="bordered-special"
              onChange={(e) => selectedPaymentMethod(e)}
              value={data.getDetailsORC.paymentMethod}
            >
              <Radio value={"tidypay"}>Tidypay</Radio>
              <Radio value={"dintero"}>Dintero</Radio>
            </Radio.Group>
          </div>
        </div>
        {/* ----------------------------| TidyPay TreeDrive |----------------------------- */}
        <div style={{ margin: "7vh 0" }}>
          <Divider>
            <label className="label">Admin Payment For TreeDrive</label>
          </Divider>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Account Id</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    required
                    className="input"
                    name="accountId"
                    placeholder="accountId"
                    value={value.getDetailsTD.accountId}
                    onChange={(e) => handleChange2(e)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-phone" />
                  </span>
                </p>
              </div>
              <div className="field-label is-normal">
                <label className="label">Terminal Id</label>
              </div>
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    required
                    className="input"
                    name="terminalId"
                    placeholder="terminalId"
                    value={value.getDetailsTD.terminalId}
                    onChange={(e) => handleChange2(e)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-file-alt" />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Tidypay Username</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    required
                    className="input"
                    name="tidypayusername"
                    placeholder="tidypayusername"
                    value={value.getDetailsTD.tidypayusername}
                    onChange={(e) => handleChange2(e)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-phone" />
                  </span>
                </p>
              </div>
              <div className="field-label is-normal">
                <label className="label">Tidypay Password</label>
              </div>
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    required
                    className="input"
                    name="tidypaypassword"
                    placeholder="tidypaypassword"
                    value={value.getDetailsTD.tidypaypassword}
                    onChange={(e) => handleChange2(e)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-file-alt" />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Tidypay Password Web</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    required
                    className="input"
                    name="tidypaypasswordweb"
                    placeholder="tidypaypasswordweb"
                    value={value.getDetailsTD.tidypaypasswordweb}
                    onChange={(e) => handleChange2(e)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-phone" />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label" />
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button
                    className="button is-primary"
                    onClick={() => addDetailsFunc("value")}
                  >
                    Save Restaurant
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPayment;
